.group1{
    display: flex;
    flex-direction: column;
    margin: 7.24vw 0 auto 7.89vw;
}

.group1 .heading{
    font:1.05vw geist-med;
    color:#455A64;
    margin:0 auto 0 0;
    letter-spacing: 0.1vw;
}

.group1 .bold{
    font:2.77vw epilogue-bold;
    color:black;
    margin:0.66vw auto 0 0;
}

.group1 .desc{
    font:0.92vw geist-reg;
    color:#455A64;
    letter-spacing: 0.07vw;
    margin: 2.63vw auto 0 0;
}

.group1 .a{
    display: flex;
    text-decoration: none;
    border-radius: 3.29vw;
    padding: 0.66vw 1.64vw;
    background-color: #022634;
    margin:2.63vw auto 0 0;
}

.group1 .a:hover{background-color: #0b3d4f;}

.group1 .a p{
    font:1.18vw geist-reg;
    letter-spacing: 0.1vw;
    color:#0DD171;
    margin:auto 0;
}

.group1 .a img{
    margin: auto 0 auto 0.66vw;
    width: 1.577vw;
    height: 1.577vw;
}