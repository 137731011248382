@font-face {
    font-family: geist-bold;
    src: url("./Geist-Bold.ttf");
}

@font-face {
    font-family: geist-reg;
    src: url("./Geist-Regular.ttf");
}

@font-face {
    font-family: geist-med;
    src: url("./Geist-Medium.ttf");
}

@font-face {
    font-family: epilogue-bold;
    src: url("./Epilogue-Bold.ttf");
}

@font-face {
    font-family: epilogue-reg;
    src: url("./Epilogue-Regular.ttf");
}

@font-face {
    font-family: epilogue-med;
    src: url("./Epilogue-Medium.ttf");
}

@font-face {
    font-family: epilogue-semibold;
    src: url("./Epilogue-SemiBold.ttf");
}

@font-face {
    font-family: poppins-bold;
    src: url("./Poppins-Bold.ttf");
}

@font-face {
    font-family: poppins-reg;
    src: url("./Poppins-Regular.ttf");
}

@font-face {
    font-family: poppins-med;
    src: url("./Poppins-Medium.ttf");
}

@font-face {
    font-family: poppins-semibold;
    src: url("./Poppins-SemiBoldItalic.ttf");
}

@font-face {
    font-family: poppins-light;
    src: url("./Poppins-Light.ttf");
}