.addcontact{
    display: flex;
    flex-direction: column;
    margin:1vw auto 6.57vw 2vw;
}

.addcontact .back{
    width:1.97vw;
    height:auto;
    margin:1.32vw auto 0 0;
    padding: 0.46vw;
    border-radius: 0.66vw;
    cursor: pointer;
}

.addcontact .back:hover{
    background-color: rgba(0, 0, 0, 0.1);
}

.addcontact .heading{
    font:1.31vw poppins-bold;
    color:#263238;
    margin:0.66vw auto 0 0.66vw;
}

.addcontact .desc{
    font:0.99vw poppins-reg;
    color:#1E293B99;
    margin: 0.33vw auto 0 0.66vw;
    line-height: 1.12vw;
}

.addcontact form{
    display: flex;
    flex-direction: column;
    background-color: white;
    padding:1.97vw 1.32vw 1.32vw 1.32vw;
    border-radius: 1.31vw;
    margin:1.97vw auto 0 0.66vw;
}

.addcontact form .inputs{
    display: flex;
    margin: 0 auto;
}

.addcontact form .inputs .inpleft, .addcontact form .inputs .inpright{
    display: flex;
    flex-direction: column;
    margin:0;
}

.addcontact form .inputs .inpright{
    margin-left: 1.97vw;
}

.inputs .label{
    font:0.99vw poppins-med;
    color:#1E293B;
    margin:0 auto 0 0;
}

.inputs .inpDiv1, .inputs .inpDiv2{
    display: flex;
    margin:0.66vw auto 2.3vw 0;
    background-color: #F4F6F8;
    border-radius: 1.31vw;
    border: 0.07vw solid #CBD5E1;
    padding:0.79vw;
    box-sizing: border-box;
    width: 23.03vw;
}

.inpDiv1 img{
    width: 1.45vw;
    height:1.45vw;
    margin:auto 0;
}

.inpDiv1 input, .inpDiv2 input{
    border:0;
    background-color: transparent;
    font:0.99vw poppins-reg;
    color:#1E293B;
    margin:auto 0 auto 0.66vw;
}

.inpDiv1 input:focus, .inpDiv2 input:focus{
    outline: 0;
}

.inputs .inpDiv2{
    padding:0;
    border-radius: 3.29vw;
}

.inpDiv2 span {
    display: flex;
    margin:0 0.66vw 0 0.33vw;
    height:3.29vw;
    width:0.13vw;
    background-color: #CBD5E1;
}

.inpDiv2 .cnum{
    font:0.99vw poppins-reg;
    margin:auto 0;
    color:#475569;
}

.countryDD{
    display: flex;
    flex-direction: column;
    margin:auto 0;
    border-radius: 3.29vw;
    padding: 0.66vw;
    cursor: pointer;
    height: 1.64vw;
    background-color: #F4F6F8;
    z-index: 1;
}

.countryDD .front{
    display: flex;
    margin:0;
}

.countryDD .front img:nth-child(1){
    width:1.64vw;
    height:1.64vw;
    margin:auto 0;
}

.countryDD .front img:nth-child(2){
    width:1.18vw;
    height:auto;
    margin:auto 0 auto 0.66vw;
}

.countryDD .drop{
    display: flex;
    flex-direction: column;
    margin:0 auto 0 0;
    border:0.07vw solid rgba(0, 0, 0, 0.2);
    border-top: 0;
    border-radius:0 0 3.29vw 3.29vw;
    position: relative;
    right:0.66vw;
}

.countryDD .drop div{
    display: flex;
    width:3.48vw;
    margin:0;
    background-color: #F4F6F8;
}

.countryDD .drop div img{
    width:1.64vw;
    height:1.64vw;
    padding: 0.66vw 0;
    margin:auto;
}

.addcontact form .priv{
    display: flex;
    margin:1.32vw auto 0 0.66vw;
}

.addcontact form .priv span{
    display: flex;
    width:1.31vw;
    height: 1.31vw;
    border-radius: 3.29vw;
    background-color: #F4F6F8;
    border: 0.07vw solid #CBD5E1;
    cursor: pointer;
    margin: auto 0;
}

.addcontact form .priv span div{
    display: flex;
    width: 0.85vw;
    height: 0.85vw;
    border-radius: 3.29vw;
    margin: auto;
}

.addcontact form .priv p{
    font:0.85vw poppins-reg;
    color:#475569;
    margin:auto 0 auto 0.66vw;
}

.addcontact form button{
    font:0.99vw poppins-med;
    color:white;
    background-color: #022634;
    border-radius: 3.29vw;
    padding:0.66vw 0;
    margin:1.32vw auto;
    cursor: pointer;
    width: 95%;
}

.addcontact .error{
    display: flex;
    font:0.723vw poppins-light;
    color:red;
    margin:-1.972vw auto 0.887vw 0.657vw;
}