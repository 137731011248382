.section8{
    display: flex;
    width:100%;
    margin:0;
    height: fit-content;
    background-color: #00202C;
}

.section8 .sec8pt1{
    display: flex;
    flex-direction: column;
    margin:6.58vw 0 6.58vw 7.89vw;
}

.section8 .sec8pt1 p:nth-child(1), .section8 .sec8pt1 p:nth-child(2){
    font:2.3vw epilogue-bold;
    color:white;
    margin:0 auto 0 0;
}

.section8 .sec8pt1 p:nth-child(2){
    color:#0DD171;
    margin-top: 0.46vw;
}

.section8 .sec8pt1 p:nth-child(3){
    margin:1.32vw auto 0 0;
    color:white;
    font:1.05vw epilogue-reg;
    max-width: 30.92vw;
}

.section8 .vector{
    width:19.08vw;
    height: 19.08vw;
    margin:auto 0 auto 7.89vw;
}

.section8 .link{
    display: flex;
    background-color: #0DD171;
    box-shadow: 0 -0.07vw 0.75vw 0 #0DD171;
    padding:0.66vw 1.97vw;
    border-radius: 20px;
    margin:auto auto auto 9.87vw;
}

.section8 .link:hover{
    background-color: #12a15c;
}

.section8 .link p{
    font:0.99vw geist-med;
    color:#00202C;
    margin:auto 0;
    letter-spacing: 0.07vw;
}

.section8 .link img{
    width:1.32vw;
    height: auto;
    margin:auto 0 auto 0.66vw;
}