.popup *{
    box-sizing: content-box;
}

.popup{
    display:flex;
    margin:0;
    flex-direction: column;
    width:100%;
    height:100vh;
    position: fixed;
    z-index: 4;
    -webkit-backdrop-filter: blur(0.26vw);
    backdrop-filter: blur(0.26vw);
    background-color: rgba(0, 0, 0, 0.015);
}

.popup .main{
    display: flex;
    flex-direction: column;
    margin:auto;
    background-color: #E7FFF3;
    padding:1.31vw;
    border-radius: 1.31vw;
}

.popup .main img{
    margin:-0.46vw 0 0 auto;
    width:1.44vw;
    height: 1.44vw;
    cursor: pointer;
    padding:0.46vw;
}

.popup .main p:nth-child(2){
    font: 1.31vw poppins-med;
    color:black;
    margin:0.66vw auto 0 auto;
}

.popup .main span{
    color:#00FF14;
}

.popup .main p:nth-child(3){
    font:0.99vw poppins-med;
    color:white;
    background-color:#022634;
    padding:0.53vw 0.99vw;
    border-radius: 0.66vw;
    margin:0.99vw auto 0 auto;
    cursor: pointer;
}