.contact *{
    box-sizing: content-box;
}

.contact{
    display: flex;
    width:100%;
    min-height:100vh;
    margin:0;
    background-color: #F4F6F8;
}

.contact .main{
    display: flex;
    flex-direction: column;
    width:80vw;
    min-height: 100vh;
    margin:0 auto auto 0;
}

.contact .contactpt1{
    display: flex;
    width:95%;
    margin:3.29vw 0 0 auto;
}

.contact .contactpt1 .lft{
    display: flex;
    flex-direction: column;
    margin:0;
}

.contact .contactpt1 .lft p:nth-child(1){
    font:1.31vw poppins-bold;
    color:#263238;
    margin:0 auto 0 0;
}

.contact .contactpt1 .lft p:nth-child(2){
    font:0.99vw poppins-reg;
    color:#1E293B99;
    margin:0.33vw auto 0 0;
    line-height: 1.12vw;
}

.contact .contactpt1 .btn{
    font:0.99vw poppins-med;
    color:white;
    background-color: #022634;
    margin:0 0 auto auto;
    border-radius: 0.66vw;
    padding:0.53vw 0.99vw;
    cursor: pointer;
}

.contact .contactpt1 .btn:hover{
    background-color: #073648;
}

.contact .export{
    display: flex;
    margin:0 0 0 auto;
    padding:0.66vw;
    border-radius: 0.33vw;
    cursor: pointer;
}

.contact .export img{
    width:1.31vw;
    height: 1.31vw;
    margin:auto;
}

.contact .export p{
    font:0.85vw poppins-med;
    color:#263238;
    margin:auto 0.66vw auto 0.33vw;
}

.contact .export:hover{
    background-color: rgba(0, 0, 0, 0.1);
}