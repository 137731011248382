.modal-container {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  
  .modal .btn {
    display: block;
    margin: auto;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
  }
  
  .form-group input,
  .form-group textarea,
  .form-group select {
    border: 2px solid #d8d5d5c9;
    border-radius: 0.3rem;
    padding: 0.3rem;
    font-size: 1rem;
  }
  
  .form-group label {
    margin-bottom: 0.2rem;
  }
  
  .error {
    background-color: #f8d7da;
    color: #df4759;
    padding: 0.5rem;
    border-radius: 0.3rem;
    margin-bottom: 1rem;
  }