.headercard{
    display: flex;
    background-color: white;
    margin: 3.29vw auto 0 auto;
    flex-wrap: wrap;
    column-gap:7.89vw;
    padding: 1.64vw 5.26vw 0 5.26vw;
    border-radius: 0.33vw;
    box-shadow: 0 0 3.29vw 0.13vw #0DC70026;
    z-index: 1;
}

.headercard div{
    display: flex;
    margin:0;
    padding-bottom: 1.45vw;
    border-bottom: 0.2vw solid #3DDA8D;
}

.headercard div p:nth-child(1){
    font:1.18vw geist-med;
    color:#02232F;
    padding: 0.66vw 0.72vw;
    border-radius: 50px;
    border:0.07vw solid #455a6466;
    margin:auto 0;
}

.headercard div p:nth-child(2){
    font:1.18vw geist-med;
    color:#02232F;
    margin:auto 0 auto 0.66vw;
}