.brands{
    display: flex;
    width: 100%;
    padding: 2.96vw 0 2.4vw 0;
    background-color: #EAFFB2;
}

.brands img{
    width: 18.4vw;
    height:auto;
    margin-left: 5.59vw;
}
