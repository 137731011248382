.topbar *{
    box-sizing: content-box;
}

.topbar{
    display: flex;
    margin:0;
    width:100%;
    background-color: white;
    padding: 1.31vw 0;
    border-radius: 0 0 20px 20px;
}

.topbar .srch{
    display: flex;
    background-color: #F4F6F8;
    padding:0.9vw;
    border-radius: 3.29vw;
    box-shadow: 0 0.26vw 0.39vw 0 #00000005;
    margin:auto 0 auto 2.5vw;
}

.topbar .srch img{
    width:1.64vw;
    height:1.64vw;
    margin:auto 0;
}

.topbar .srch input{
    width: 55.87vw;
    border:0;
    background-color: transparent;
    font:0.99vw poppins-med;
    color:#505470;
    margin: auto auto auto 1.31vw;
    padding: 0;
}

.topbar .srch input:focus{
    outline:0;
}

.topbar .srch input::placeholder{
    color: rgba(0, 0, 0, 0.3);
}

.topbar .notif{
    margin:auto 0 auto 1.31vw;
}

.topbar .notif img{
    width:1.97vw;
    height:1.97vw;
    padding:0.66vw;
    border-radius: 50%;
    background-color: #F4F6F8;
    margin:0;
}

.topbar .notif img:hover{
    background-color: #eeeeee;
}

.topbar .account{
    display: flex;
    margin:auto auto auto 0.66vw;
    padding:0.66vw 0.99vw;
    cursor: pointer;
    border-radius: 50px;
    border:1px solid rgba(0, 0, 0, 0.1);
}

.topbar .account .profile{
    width:2.63vw;
    height:2.63vw;
    margin:auto 0;
    border-radius: 50px;
}

.topbar .account .tempdiv{
    display: flex;
    flex-direction: column;
    margin:auto 0 auto 0.66vw;
}

/* .topbar .account .tempdiv p:nth-child(1){
    font:0.72vw poppins-reg;
    margin:0 auto 0 0;
    color:#292D3270;
} */

.topbar .account .tempdiv p:nth-child(1){
    font:0.99vw poppins-med;
    margin:0 auto 0 0;
}

.topbar .account .arrow{
    width:0.99vw;
    height:auto;
    margin:auto 0 auto 0.66vw;
}

.topbar .account:hover{
    background-color: #F4F6F8;
}