.section2{
    display: flex;
    width: 100%;
    margin: 0;
    background-image: url("../../images/forSection2/Frame.png");
    height: 28.33vw;
    background-repeat: no-repeat;
    background-size: cover;
}

.section2 .adcard{
    margin:auto 9.55vw 3.29vw auto;
    width: 35vw;
    height: 20.31vw;
}