.left{
    display: flex;
    flex-direction: column;
    margin:11.5vw 0 auto 6.57vw;
}

.left .p1{
    display: flex;
    font:1.18vw epilogue-med;
    margin: 0 auto 0 0;
    color:white;
    letter-spacing: 0.08vw;
}

.left .p2{
    display: flex;
    font:3.15vw epilogue-bold;
    color:white;
    margin:1.64vw auto 0 0;
    letter-spacing: 0.1vw;
}

.left .p2 span{color:#9AFECF; margin:0; margin-right: 0.99vw;}

.left .p3{
    font:0.92vw epilogue-reg;
    color:rgba(255, 255, 255, 0.6);
    margin: 0.66vw auto 0 0;
    letter-spacing: 0.1vw;
    line-height: 1.12vw;
}

.left .getstarted{
    display: flex;
    font:0.92vw epilogue-med;
    text-decoration: none;
    color:#0D100E;
    background-color: #62E4A4;
    margin: 3.94vw auto 0 0;
    border-radius: 3.29vw;
    padding: 0.72vw 1.64vw 0.59vw 1.64vw;
}

.left .getstarted:hover{background-color: #47b27d;}

.left .p4{
    font:0.92vw epilogue-reg;
    color:rgba(255, 255, 255, 0.7);
    margin: 1.31vw auto auto 0;
}
