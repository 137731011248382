.privacy-policy-container {
  font-family: "Roboto", Arial, sans-serif;
  padding: 25px;
  line-height: 1.8;
  color: #333;
  background-color: #ffffff;
  border-radius: 15px;
  /* box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); */
  max-width: 900px;
  margin: 20px auto;
}

.privacy-policy-title {
  font-size: 2.8em;
  margin-bottom: 15px;
  color: #1a73e8;
  text-align: center;
  font-weight: 700;
  border-bottom: 2px solid #e0e0e0;
  padding-bottom: 10px;
}

.last-updated {
  font-size: 0.95em;
  color: #666;
  margin-bottom: 25px;
  text-align: center;
  font-style: italic;
}

.policy-section {
  margin-bottom: 40px;
}

.policy-section h2 {
  font-size: 1.8em;
  margin-bottom: 12px;
  color: #1a73e8;
  font-weight: 600;
  text-transform: uppercase;
}

.policy-section h3 {
  font-size: 1.4em;
  margin-bottom: 10px;
  color: #424242;
  font-weight: 500;
}

.policy-section p {
  font-size: 1em;
  margin-bottom: 20px;
  line-height: 1.7;
  color: #555;
}

.policy-section ul {
  margin-left: 25px;
  list-style-type: disc;
  color: #444;
}

.policy-section ul li {
  margin-bottom: 12px;
  font-size: 1em;
}

.policy-section ul li b {
  color: #1a73e8;
  font-weight: 600;
}

.policy-section a {
  color: #1a73e8;
  text-decoration: underline;
  transition: color 0.3s ease;
}

.policy-section a:hover {
  color: #0056b3;
}

.tophead{
  display: flex;
  width:100%;
  margin:0;
  box-sizing: border-box;
  padding:20px;
  flex-direction: row;
}

.tophead .logo{
  width:150px;
  height:auto;
  margin:auto 0;
}

.tophead span p{
  font:20px poppins-med;
  margin:10px auto 0 auto;
  display: flex;
  width: fit-content;
}

.tophead .sas img{
  width:50px;
  height:50px;
  margin:auto 0;
}

.tophead .sas{
  display: flex;
  flex-direction: row;
  margin:auto;
}

.tophead .sas p{
  margin:auto 0 auto 10px;
  font:30px poppins-bold;
}

@media (max-width: 768px) {
  .privacy-policy-container {
    padding: 20px;
  }

  .privacy-policy-title {
    font-size: 2.2em;
  }

  .policy-section h2 {
    font-size: 1.5em;
  }

  .policy-section h3 {
    font-size: 1.2em;
  }

  .policy-section p,
  .policy-section ul li {
    font-size: 0.95em;
  }
}

@media (max-width: 480px) {
  .privacy-policy-container {
    padding: 15px;
  }

  .privacy-policy-title {
    font-size: 2em;
  }

  .policy-section h2 {
    font-size: 1.4em;
  }

  .policy-section h3 {
    font-size: 1.1em;
  }

  .policy-section p,
  .policy-section ul li {
    font-size: 0.9em;
  }
}
