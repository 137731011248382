.faq{
    display: flex;
    flex-direction: column;
    margin:3.29vw auto 6.61vw auto;
    width:62.5vw;
    box-shadow: 0 0.26vw 0.26vw 0 #00000040;
}

.faqcard{
    display: flex;
    flex-direction: column;
    width:100%;
    margin:0;
    background-color: white;
    padding: 1.32vw 0;
    border-radius: 0.13vw;
    border-top: 0.05vw solid #455A644D;
}

.faqcard .front{
    display: flex;
    width:100%;
    margin:0;
}

.faqcard .front p{
    font:1.32vw epilogue-reg;
    margin:auto 0 0.329vw 4.61vw;
    color:#455A64;
    max-width: 44vw;
    line-height: 1.97vw;
}

.faqcard .front img{
    width: 1.64vw;
    height: 1.64vw;
    margin:auto 3.29vw auto auto;
    padding:0.66vw;
    border-radius: 50%;
    cursor: pointer;
}

.faqcard .front img:hover{
    background-color: rgba(0, 0, 0, 0.1);
}

.faqcard .content{
    max-width: 49.34vw;
    margin:1.32vw auto 0 4.61vw;
    font:1.12vw epilogue-reg;
    color: #455a64ad;
    line-height: 1.45vw;
    padding-bottom: 1.32vw;
}