.calender{
    display: flex;
    flex-direction: column;
    margin:auto;
    background-color: #F4F6F8;
    padding: 0 0.66vw;
    cursor: pointer;
}

.calender .dateDisplay{
    font:0.99vw poppins-reg;
}

.calender img{
    width:0.99vw;
    height:0.99vw;
    margin:auto 0 auto 1.31vw;
}

.analytics{
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding-bottom: 6.57vw;
    width:100%;
    background-color: #F4F6F8;
    height: fit-content;
}

.analytics .header{
    font: 1.64vw poppins-med;
    color:black;
    margin:1.97vw auto 0 3vw;
}

.analytics .searchFilter{
    display: flex;
    margin:1.31vw auto 0 3vw;
}

.analytics .searchFilter .search{
    display: flex;
    margin: auto auto auto 0;
    background-color: white;
    border-radius: 0.33vw;
    padding:0.66vw;
}

.analytics .searchFilter .search img{
    margin: auto;
    width: 1.44vw;
    height:1.44vw;
}

.analytics .searchFilter .search input{
    margin: auto 0 auto 1.31vw;
    width:34vw;
    font: 0.99vw poppins-reg;
    color:black;
}

.analytics .searchFilter .search input:focus{outline: 0;}

.analytics .searchFilter .dateSelector{
    display: flex;
    margin:auto auto auto 1.31vw;
    background-color: white;
    padding: 0.66vw;
    border-radius: 0.33vw;
}

.analytics .searchFilter .dateSelector .ind{
    margin:auto 0.66vw auto 0;
    font:0.99vw poppins-med;
}

.analytics .metrics{
    display: flex;
    margin:1.97vw auto 0 3vw;
    gap:1.31vw;
    flex-wrap: wrap;
    max-width:78vw;
}

.analytics .metrics .metric{
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    background-color: white;
    border-radius: 0.66vw;
    padding: 1.31vw;
    width: 18.4vw;
}

.analytics .metrics .metric .metricName{
    font:1.1vw poppins-reg;
    color: #554564;
    margin:0 auto 0.66vw 0;
}

.analytics .metrics .metric .metricValue{
    font:1.31vw poppins-med;
    color: #554564;
    margin:auto auto 0.66vw auto;
}

.analytics .metrics .metric .dataInsight{
    /* display: none;  temporarily */
    font:0.99vw poppins-reg;
    color: #4AA873;
    margin:auto auto 0 0;
}

.analytics .banner{
    width:77vw;
    display:  flex;
    margin:2.63vw auto 0 3vw;
    padding:1.31vw;
    border-radius: 1.31vw;
    background-color: #022634;
    background-image: url("../assests/analytics/bannerbg.png");
    background-size: cover;
}

.analytics .banner .txt{
    display:  flex;
    flex-direction: column;
    margin:auto auto auto 3.29vw;
}

.analytics .banner .txt p:nth-child(1){
    font:1.64vw poppins-bold;
    color:white;
}

.analytics .banner .txt p:nth-child(2){
    font:0.85vw poppins-reg;
    color:rgba(255, 255, 255, 0.6);
}

.analytics .banner .txt p:nth-child(3){
    margin:0.99vw auto 0 0;
    font:0.99vw poppins-bold;
    color:white;
    background-color: #0DD171;
    border-radius: 3.29vw;
    padding:0.53vw 1.31vw;
    cursor: pointer;
}

.analytics .banner img{
    margin:auto 3.94vw 1.97vw auto;
    width:15.44vw;
    height:13.14vw;
}