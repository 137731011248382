.right{
    display: flex;
    margin: auto auto 0 16vh;
    height: fit-content;
}

.right .whatsapp{
    width:3vw;
    height: 3vw;
    margin:0 0 auto 0;
}

.right .chatpic{
    width:22vw;
    height: 35vw;
    margin: 10.66vh 0 0 1.33vh;
}

.right .chat3d{
    width:6vw;
    height:6vw;
    margin:auto 0 6vh 2.66vh;
}
