.clienthome{
    display: flex;
    flex-direction: column;
    margin:0;
    width:100%;
    background-color: #f0f2f4;
    padding-bottom: 6.57vw;
}

.clienthome *{box-sizing: content-box;}

.clienthome .banner{
    width:85%;
    margin:1.31vw auto 0 auto;
    border-radius: 1.31vw;
    display: flex;
    padding:3.29vw;
    background-image: url("../assests/analytics/bannerbg.png");
    background-size: cover;
}

.clienthome .banner div{
    display: flex;
    flex-direction: column;
    margin:auto auto auto 5.26vw;
}

.clienthome .banner div p:nth-child(1){
    font: 1.97vw poppins-med;
    color:white;
    margin:0 auto 0 0;
}

.clienthome .banner div p:nth-child(2){
    font: 0.99vw poppins-reg;
    color:rgba(255, 255, 255, 0.5);
    margin:0 auto 0 0;
}

.clienthome .banner div .upg{
    margin:0.66vw auto 0 0;
    padding:0.46vw 0.99vw;
    border-radius: 3.29vw;
    background-color: #0DD171;
    font:0.99vw poppins-med;
    color:white;
}

.clienthome .banner img{
    width:14.46vw;
    height:auto;
    margin: auto 5.26vw 1.31vw auto;
}

.clienthome .header1{
    font:1.31vw poppins-med;
    color:black;
    margin:1.97vw auto 0 3.94vw;
}

.clienthome .header1 span{
    color:#0DD171;
}

.clienthome .funcs{
    display: flex;
    flex-wrap: wrap;
    gap:1.31vw;
    margin:1.31vw auto 0 3.94vw;
}

.clienthome .funcs .func{
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 0.66vw;
    padding:0.99vw;
    width:15.78vw;
    height:8.54vw;
    cursor: pointer;
}

.clienthome .funcs .func:hover{
    outline: 0.13vw solid #0DD171;
}

.clienthome .funcs .func img{
    margin:auto;
    width:3.29vw;
    height:3.29vw;
}

.clienthome .funcs .func p{
    font:1.12vw poppins-reg;
    color:black;
    margin:auto;
    text-align: center;
}

.clienthome .funcs .feat{
    display: flex;
    background-color: #374151;
    border-radius: 0.99vw;
    padding:1.31vw;
    width:21.37vw;
}

.clienthome .funcs .feat div{
    display: flex;
    flex-direction: column;
    margin:auto auto auto 0;
}

.clienthome .funcs .feat div p:nth-child(1){
    font:1.31vw poppins-med;
    color:white;
    margin:0 auto 0 0;
}

.clienthome .funcs .feat div p:nth-child(2){
    font:0.99vw poppins-med;
    color:#0DD171;
    background-color: white;
    padding:0.46vw 0.99vw;
    border-radius: 3.29vw;
    margin:0.66vw auto 0 0;
    cursor: pointer;
}

.clienthome .funcs .feat div p:nth-child(2):hover{background-color:rgb(216, 216, 216);}

.clienthome .funcs .feat img{
    width:8.02vw;
    height:auto;
    margin:auto 0 auto auto;
}