.maincard{
    display:flex;
    flex-wrap: wrap;
    column-gap: 2.63vw;
    margin:0 auto 5.92vw auto;
}

.maincard p{
    font:1.18vw geist-reg;
    color:#455a64c2;
    max-width: 21.03vw;
    background-color: white;
    padding: 1.97vw 1.31vw;
    margin:0;
    border-radius: 0.33vw;
}