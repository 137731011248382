.section5{
    display: flex;
    flex-direction: column;
    width:100%;
    margin: 0;
}

.section5 .heading{
    display: flex;
    font:2.3vw epilogue-bold;
    color:#022634;
    margin:6.57vw auto 0 auto;
    letter-spacing: 0.07vw;
}

.section5 .heading span{
    display: flex;
    font-size: 0.79vw;
    color:#4ECB71;
    border-radius: 50px;
    background-color: #022634;
    padding: 0.46vw 0.86vw 0.26vw 0.86vw;
    margin:auto 0 0.66vw 0.66vw;
}

.section5 .unit{
    display:flex;
    margin:3.29vw auto 6.57vw auto;
}

.section5 .unit .leftp{display: flex; flex-direction: column; margin: 1.31vw 0 auto 0;}

.section5 .unit .leftp p:nth-child(1), .section5 .unit .rightp p:nth-child(1){
    font:1.97vw epilogue-semibold;
    color:#022634;
    margin:0 auto 0 0;
}

.section5 .unit .leftp p:nth-child(2), .section5 .unit .rightp p:nth-child(2){
    font:0.99vw epilogue-reg;
    color:#022634;
    margin:1.31vw auto 0 0;
}

.section5 .unit img{
    width: 32.86vw;
    height: 19.72vw;
    margin:0 0 0 8vw;
}

.section5 .unit .rightp p:nth-child(1){
    margin:7.89vw 0 0 8vw;
    text-align: right;
}

.section5 .unit .rightp p:nth-child(2){
    margin:1.31vw 0 0 auto;
    text-align: right;
}