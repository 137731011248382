.dropdown{
    display: flex;
    margin:auto 0 auto 3.29vw;
    flex-direction: column;
    cursor: pointer;
    max-height: 2.96vw;
    user-select: none;
    z-index: 1;
}

.dropdown .front{
    display: flex;
    padding: 0.66vw;
}

.dropdown .front:hover{background-color: rgba(0, 0, 0, 0.1);}

.dropdown .front .flag{
    width:2.3vw;
    height: auto;
    margin:auto 0;
}

.dropdown .front .arrow{
    width: 1.12vw;
    margin: auto 0 auto 0.99vw;
    transition: 0.2s;
}

.dropdown .below{
    display: flex;
    flex-direction: column;
    transition: 1s;
    background-color: white;
}

.dropdown .below div{
    display: flex;
    padding: 0.66vw;
    margin:0;
    border-top: 0.07vw solid rgba(0, 0, 0, 0.2);
}

.dropdown .below div p{
    font:0.79vw geist-reg;
    color:#3A3A3A;
    letter-spacing: 0.13vw;
    margin: auto 0 auto auto;
}

.dropdown .below div img{
    width: 2.3vw;
    height: auto;
    margin:0;
}

.dropdown .below div:hover{background-color: rgb(220, 220, 220);}
