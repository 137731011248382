.section6{
    display:flex;
    width:100%;
    height:fit-content;
    margin:0;
    background-image: url("../../images/forSection6/frame3.png");
    background-size: cover;
    background-color: #022634;
}

.section6 .sec6pt1{
    display: flex;
    flex-direction: column;
    margin:3.94vw 0 auto 0.99vw;
}

.section6 .sec6pt1 img:nth-child(1){
    width:5.92vw;
    height:5.92vw;
    margin:0 auto 0 5.92vw;
}

.section6 .sec6pt1 img:nth-child(2){
    width: 26.29vw;
    height: auto;
    margin:3.29vw 0 0 2.63vw;
}

.section6 .sec6pt2{
    display: flex;
    flex-direction: column;
    margin:5.26vw 0 auto 0;
}

.section6 .sec6pt2 .heading{
    font:2.63vw epilogue-bold;
    margin:0 auto;
    color:white;
    letter-spacing: 0.07vw;
}

.section6 .sec6pt2 .desc{
    font:1.05vw epilogue-reg;
    color:white;
    margin:3.94vw auto 0 auto;
    text-align: center;
    max-width: 42.73vw;
    letter-spacing: 0.08vw;
}

.section6 .sec6pt2 .box{
    display: flex;
    margin:6.57vw auto auto auto;
}

.section6 .sec6pt2 .box div{
    display: flex;
    flex-direction: column;
    margin:0;
}

.section6 .sec6pt2 .box div p:nth-child(1){
    font:2.76vw epilogue-bold;
    color:white;
    margin:0 auto;
}

.section6 .sec6pt2 .box div p:nth-child(2){
    font:0.92vw epilogue-reg;
    color:#0DD171;
    margin:0.99vw auto 0 auto;
}

.section6 .sec6pt3{
    width:14.46vw;
    height:14.46vw;
    margin:auto 6.57vw 5.26vw auto;
}