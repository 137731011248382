.table{
    display: flex;
    flex-direction: column;
    background-color: white;
    box-sizing: border-box;
    padding:1.64vw 0.99vw 6.57vw 0.99vw;
    border-radius: 1.31vw;
    width:74.49vw;
    margin:0.33vw 0 auto auto;
}

.table .attr{
    width:100%;
    display: flex;
    margin:0 0 0.66vw 0;
    flex-wrap: wrap;
    background-color: #F7F8FA;
    box-sizing: border-box;
    padding:0.66vw 0;
    border-radius: 0.66vw;
}

.table .attr p{
    font:0.99vw poppins-med;
    margin:0 0 0 3.94vw;
    color:#505470;
    width: 9.86vw;
}

.table .tuple{
    display: flex;
    width:100%;
    margin:0;
    padding: 0.99vw 0;
    border-bottom: 0.07vw solid rgba(0, 0, 0, 0.1);
}

.table .tuple p{
    font:0.99vw poppins-reg;
    color:#505470;
    margin:auto 0 auto 3.94vw;
    width: 9.86vw;
}

.table .tuple .actions{
    display: flex;
    margin:auto auto auto 3.94vw;
}

.table .tuple .actions img{
    padding:0.33vw;
    border-radius: 0.33vw;
    width: 1.25vw;
    height: 1.25vw;
    cursor: pointer;
}

.table .tuple .actions img:hover{
    background-color: rgba(0, 0, 0, 0.1);
}