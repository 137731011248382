.landing *{
    box-sizing: content-box;
    /* animation: appear 5s ease-out;
    animation-timeline: view();
    animation-range: entry 0 cover 20%; */
}

.landing{
    display: flex;
    flex-direction: column;
    margin: 0;
    width:100%;
}
/* 
.landing .credits *{
    animation: none;
}

@keyframes appear {
    from {
        opacity: 0;
        scale:0.5
    }
    to {
        opacity: 1;
        scale: 1;
    }
} */