.section7{
    display: flex;
    flex-direction: column;
    width:100%;
    margin:0;
    height:39.43vw;
    background-image: url("../../images/forSection7/sec7bg.png");
    background-size: cover;
}

.section7 p:nth-child(1){
    font:1.05vw epilogue-reg;
    color:#0DD171;
    background-color: #022634;
    border-radius: 50px;
    padding: 0.79vw 1.97vw 0.79vw 1.97vw;
    margin:7.89vw auto 0 auto;
}

.section7 p:nth-child(2){
    font:2.63vw epilogue-semibold;
    color:#022634;
    text-align: center;
    margin:2.63vw auto 0 auto;
    letter-spacing: 0.1vw;
    max-width: 36.15vw;
}

.section7 p:nth-child(3){
    font:1.05vw epilogue-reg;
    color:#022634;
    margin:1.97vw auto auto auto;
    max-width: 39.43vw;
    text-align: center;
}