.section3{
    display: flex;
    width:100%;
    margin: 0;
    background-image: url("../../images/forSection3/Frame 2.png");
    background-size: cover;
    height: fit-content;
}

.section3 .features{
    display: flex;
    flex-direction: column;
    background-color: #022634;
    width:38.12vw;
    height:26.3vw;
    margin:5.26vw 0 5.26vw 8.54vw;
}

.section3 .features p:nth-child(1), .section3 .features p:nth-child(2){
    font:2.63vw epilogue-bold;
    color:white;
    margin:3.94vw auto 0 2.63vw;
}

.section3 .features p:nth-child(2){
    margin-top: 0.33vw;
    color: #0DD171;
}

.section3 .features p:nth-child(3){
    font:1.12vw epilogue-reg;
    margin:1.31vw auto auto 2.63vw;
    color:white;
    letter-spacing: 0.07vw;
    word-spacing: 0.13vw;
    line-height: 1.51vw;
    max-width: 85%;
}

.section3 .wrap{
    display: flex;
    flex-wrap: wrap;
    margin:auto auto auto 1.97vw;
    gap:1.97vw;
    max-width: 45.99vw;
}