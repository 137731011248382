.navbar{
    display: flex;
    width:100%;
    padding: 1.31vw 0;
    margin: 0;
}

.navbar .logo{
    width: 7.89vw;
    height: auto;
    margin:auto 0 auto 6.57vw;
}

.navbar .links{
    display: flex;
    margin: auto 0 auto 13.15vw;
}

.navbar .mode{
    width:1.64vw;
    height: 1.64vw;
    cursor: pointer;
    padding: 0.66vw;
    border-radius: 3.29vw;
    margin:auto 0 auto 6.57vw;
}

.navbar .mode:hover{background-color: rgba(0, 0, 0, 0.1);}

.navbar .greenbutton{
    display: flex;
    font:0.79vw geist-bold;
    letter-spacing: 0.1vw;
    padding: 0.66vw 1.64vw;
    border-radius: 3.29vw;
    margin: auto 0 auto 1.31vw;
    background-color: #00202C;
    text-decoration: none;
    cursor: pointer;
}


.navbar .greenbutton:hover{background-color: #074052;}