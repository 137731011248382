.section4{
    display: flex;
    flex-direction: column;
    width:100%;
    margin: 0;
    background-color: #FAFAFA;
}

.section4 .heading{
    font: 2.63vw epilogue-semibold;
    color:black;
    margin:6.57vw auto 0 auto;
}